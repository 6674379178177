import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableHead, TableCell, TableRow, Typography, Grid, Tooltip, IconButton, makeStyles, Button, TextField, Collapse, Link, Box, InputAdornment, FormControl, InputLabel, Select, MenuItem, ListSubheader, FormHelperText } from "@material-ui/core";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { SpinnerContext } from "../../Context/SpinnerContext";
import CompanyService from "../../Services/CompanyService";
import SurveyService from "../../Services/SurveyService";
import UserService from "../../Services/UserService";
import SiteService from "../../Services/SiteService";
import { NotificationContext } from "../../Context/NotificationContext";
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormattedMessage, useIntl } from "react-intl";
import { Autocomplete } from "@material-ui/lab";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MessageIcon from '@material-ui/icons/Message';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import InfoIcon from '@material-ui/icons/Info';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DateFormat from "../DateFormat/DateFormat";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { randomPassword } from "../../Utilities/Password";

const useStyles = makeStyles((theme) => ({
    rowButton: {
        padding: '6px'
    },
    // Tooltip component slows down rendering when there's lots of them, hence this workaround
    tooltip: {
        position: 'relative',
        '&:hover:before': {
            content: 'attr(data-title)',
            padding: 5,
            whiteSpace: 'nowrap',
            border: '1px solid #707070',
            borderRadius: 5,
            position: 'absolute',
            top: -40,
            left: -30,
            background: '#707070',
            color: 'white',
            fontSize: 12,
            zIndex: 1401
        }
    },
    single: {
        backgroundColor: 'rgba(27, 182, 214, 0.3)'
    },
    multi: {
        backgroundColor: 'rgba(146, 191, 32, 0.3)'
    },
    all: {
        backgroundColor: 'rgba(186, 85, 211, 0.3)'
    },
}));

export default function UserAccess(props) {
    const params = useParams();
    const classes = useStyles();
    const intl = useIntl();
    const [refresh, setRefresh] = useState(0);
    const [company, setCompany] = useState(null);
    const [surveys, setSurveys] = useState(null);
    const [users, setUsers] = useState(null);
    const [sitesWithSurveys, setSitesWithSurveys] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
    const [addAccessToSiteDialogOpen, setAddAccessToSiteDialogOpen] = useState(false);
    const [usersWithNoAccessDialogOpen, setUsersWithNoAccessDialogOpen] = useState(false);
    const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
    const [addAccessToSiteAndSurvey, setAddAccessToSiteAndSurvey] = useState(null);
    const [addAccessToUser, setAddAccessToUser] = useState(null);
    const [userSiteCount, setUserSiteCount] = useState({});
    const [onlyShowSurveys, setOnlyShowSurveys] = useState([]);
    const [onlyShowUsers, setOnlyShowUsers] = useState([]);
    const [usersWithNoAccess, setUsersWithNoAccess] = useState([]);
    const [groupByDemographic, setGroupByDemographic] = useState('');
    const [surveyDemographicList, setSurveyDemographicList] = useState([]);
    const [surveyDemographics, setSurveyDemographics] = useState({});

    const [createUser, setCreateUser] = useState({
        name: '',
        email: '',
        password: '',
    });

    const [expanded, setExpanded] = useState({});
    const expandedRef = useRef();
    expandedRef.current = expanded;

    const { updateTitle } = useContext(AppBarTitleContext);
    const updateTitleRef = useRef();
    updateTitleRef.current = (newTitle) => {
        updateTitle(newTitle);
    };
    const { updateLoading } = useContext(SpinnerContext);
    const updateLoadingRef = useRef();
    updateLoadingRef.current = (loading) => {
        updateLoading(loading);
    };
    const { updateNotification } = useContext(NotificationContext);
    const updateNotificationRef = useRef();
    updateNotificationRef.current = (open, message, level) => {
        updateNotification(open, message, level);
    }

    useEffect(() => {
        updateTitleRef.current(null);
        updateLoadingRef.current(true);

        const companyReq = CompanyService.company(params.uuid);
        const surveysReq = SurveyService.allSurveysByHash('-last_respondent_at', null, {company: params.uuid});
        const usersReq = UserService.allUsers('name', null, {company: params.uuid});
        const sitesReq = SiteService.allSites('name', null, {company: params.uuid});

        Promise.all([companyReq, surveysReq, usersReq, sitesReq]).then(function(response) {
            const [compRes, surveysRes, usersReq, sitesReq] = response;
            setCompany(compRes.data.data);
            setSurveys(surveysRes);
            setUsers(usersReq);

            // For the demographic grouping <Select>
            const demListToSet = [];
            surveysRes.forEach(s => {
                demListToSet.push({isGroup: true, title: s.relationships['survey-title'] ? s.relationships['survey-title'].data.title : s.attributes.title, padding: 16});
                s.relationships.demographics.forEach(d => {
                    if (-1 === ['site', 'role', 'lengthofservice'].indexOf(d.data.demographic)) {
                        demListToSet.push({isGroup: true, title: d.data.shortName ? d.data.shortName : d.data.title, padding: 32});
                        d.data.options.forEach(opt => {
                            demListToSet.push({isGroup: false, title: opt, value: s.attributes.combinedHash+'|'+d.data.demographic+'|'+opt, padding: 48});
                        });
                    }
                });
            });
            setSurveyDemographicList(demListToSet);

            // Fetch site-demographic report for each survey for the survey demographic option filter
            surveysRes.forEach((s) => {
                SurveyService.surveyReport(s.id, 'site-demographic').then(response => {
                    // console.log(response.data.data);
                    setSurveyDemographics(prevState => ({
                        ...prevState,
                        [s.attributes.combinedHash]: response.data.data
                    }));
                }).catch(() => {
                    // Ignore
                });
            });

            // Start out with all users having no access, and mark the ones we encounter below as having acces to
            // at least one site, the IDs left as "false" at the end are the ones with no access
            const userIdsWithAccess = {};
            usersReq.forEach(u => {
                userIdsWithAccess[u.id] = false;
            });

            const sitesWithSurveysToSet = {};
            const expandedToSet = {};
            const userSiteCountToSet = {};
            if (sitesReq) {
                sitesReq.forEach(curSite => {
                    sitesWithSurveysToSet[curSite.id] = {
                        site: curSite,
                        surveys: {},
                        surveyUsers: {},
                        userDiff: false,
                        siteMapping: null,
                    };
                    surveysRes.filter(curSurvey => curSurvey.relationships.sites && curSurvey.relationships.sites.find(curSurveySite => curSurveySite.id === curSite.id)).forEach(curSurvey => {
                        sitesWithSurveysToSet[curSite.id].surveys[curSurvey.attributes.combinedHash] = curSurvey;
                    });

                    if (curSite.relationships['site-mapping']) {
                        sitesWithSurveysToSet[curSite.id].siteMapping = curSite.relationships['site-mapping'].data['site-values'].sort();
                    }

                    Object.keys(sitesWithSurveysToSet[curSite.id].surveys).forEach((curSiteSurveyCombinedHash, i) => {
                        // Use old expanded value if available, otherwise only have the first survey for each site expanded
                        expandedToSet[curSite.id+curSiteSurveyCombinedHash] = (curSite.id+curSiteSurveyCombinedHash in expandedRef.current) ? expandedRef.current[curSite.id+curSiteSurveyCombinedHash] : 0 === i;

                        usersReq.forEach(curUser => {
                            if (curUser.relationships['user-permissions'] && curUser.relationships['user-permissions'].length) {

                                for (let k = 0; k < curUser.relationships['user-permissions'].length; k++) {
                                    let toAdd = null;

                                    // User has all surveys+sites
                                    if (1 === curUser.relationships['user-permissions'][k].data.permission.survey.length &&
                                        curUser.relationships['user-permissions'][k].data.permission.survey[0] === null &&
                                        1 === curUser.relationships['user-permissions'][k].data.permission.site.length &&
                                        curUser.relationships['user-permissions'][k].data.permission.site[0] === null
                                    ) {
                                        toAdd = {user: curUser, readOnly: true, userPermission: curUser.relationships['user-permissions'][k]};
                                    }

                                    // User has all surveys + this specific site
                                    if (1 === curUser.relationships['user-permissions'][k].data.permission.survey.length &&
                                        curUser.relationships['user-permissions'][k].data.permission.survey[0] === null &&
                                        curUser.relationships['user-permissions'][k].data.permission.site.length &&
                                        -1 !== curUser.relationships['user-permissions'][k].data.permission.site.indexOf(curSite.id)
                                    ) {
                                        toAdd = {user: curUser, readOnly: true, userPermission: curUser.relationships['user-permissions'][k]};
                                    }

                                    // User has specific survey + all sites
                                    if (-1 !== curUser.relationships['user-permissions'][k].data.permission.survey.indexOf(curSiteSurveyCombinedHash) &&
                                        1 === curUser.relationships['user-permissions'][k].data.permission.site.length &&
                                        curUser.relationships['user-permissions'][k].data.permission.site[0] === null
                                    ) {
                                        toAdd = {user: curUser, readOnly: true, userPermission: curUser.relationships['user-permissions'][k]};
                                    }

                                    // User has specific survey + specific site
                                    // TODO: Find out what to do if they have [survey: [a,b], site: [site1, site2]] (in this case we would remove site from both when deleting)
                                    if (-1 !== curUser.relationships['user-permissions'][k].data.permission.survey.indexOf(curSiteSurveyCombinedHash) &&
                                        -1 !== curUser.relationships['user-permissions'][k].data.permission.site.indexOf(curSite.id)
                                    ) {
                                        toAdd = {user: curUser, readOnly: false, userPermission: curUser.relationships['user-permissions'][k]};
                                    }

                                    if (toAdd) {
                                        userIdsWithAccess[curUser.id] = true;
                                        if (!(curUser.id in userSiteCountToSet)) {
                                            userSiteCountToSet[curUser.id] = {};
                                        }

                                        if (!(curSiteSurveyCombinedHash in userSiteCountToSet[curUser.id])) {
                                            userSiteCountToSet[curUser.id][curSiteSurveyCombinedHash] = [];
                                        }

                                        if (-1 === userSiteCountToSet[curUser.id][curSiteSurveyCombinedHash].indexOf(curSite.id)) {
                                            userSiteCountToSet[curUser.id][curSiteSurveyCombinedHash].push(curSite.id);
                                        }

                                        if (!(curSiteSurveyCombinedHash in sitesWithSurveysToSet[curSite.id].surveyUsers)) {
                                            sitesWithSurveysToSet[curSite.id].surveyUsers[curSiteSurveyCombinedHash] = [];
                                        }
                                        sitesWithSurveysToSet[curSite.id].surveyUsers[curSiteSurveyCombinedHash].push(toAdd);
                                        break;
                                    }
                                }
                            }
                        });
                    });

                    // Check if user access for all surveys for a site is the same
                    const surveyUserKeys = Object.keys(sitesWithSurveysToSet[curSite.id].surveyUsers);
                    if (surveyUserKeys.length > 1) {
                        for (let i = 1; i < surveyUserKeys.length; i++) {
                            const prevUserIds = sitesWithSurveysToSet[curSite.id].surveyUsers[surveyUserKeys[i-1]].map(u => u.user.id).sort();
                            const curUserIds = sitesWithSurveysToSet[curSite.id].surveyUsers[surveyUserKeys[i]].map(u => u.user.id).sort();
                            if (JSON.stringify(prevUserIds) !== JSON.stringify(curUserIds)) {
                                sitesWithSurveysToSet[curSite.id].userDiff = true;
                            }
                        }
                    } else if (surveyUserKeys.length && surveyUserKeys.length !== Object.keys(sitesWithSurveysToSet[curSite.id].surveys).length) {
                        sitesWithSurveysToSet[curSite.id].userDiff = true;
                    }
                });
            }

            setUsersWithNoAccess(Object.keys(userIdsWithAccess).filter(uId => userIdsWithAccess[uId] === false).map(uId => {
                return usersReq.find(u => u.id === uId);
            }));
            setExpanded(expandedToSet);
            setUserSiteCount(userSiteCountToSet);
            setSitesWithSurveys(sitesWithSurveysToSet);
        }).catch((error) => {
            console.error(error);
            updateNotificationRef.current(true, 'An unknown error occurred!', 'error');
        }).then(() => {
            updateLoadingRef.current(false);
        })

    }, [params.uuid, refresh]);

    const handleDeleteUser = () => {
        // To avoid multiple submits
        if (!deleteUserDialogOpen) {
            return;
        }

        setDeleteUserDialogOpen(false);
        updateLoading(true);

        UserService.delete(deleteUserId).then(() => {
            setDeleteUserId(null);
            setRefresh(refresh+1);
        }).catch(error => {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(() => {
            updateLoading(false);
        });
    }

    const handleRemoveAccess = (userId, userPermission, siteId) => {
        updateLoading(true);
        const permission = {type: 'survey', permission: {
            survey: userPermission.data.permission.survey,
            site: userPermission.data.permission.site.filter(sId => sId !== siteId),
            report: userPermission.data.permission.report,
            company: userPermission.data.permission.company,
        }};

        // Delete instead of patch if we are removing the last site from a survey
        (permission.permission.site.length === 0 ? UserService.deletePermission(userId, userPermission.id) : UserService.patchPermission(userId, userPermission.id, permission)).then(() => {
            setRefresh(refresh+1);
        }).catch(error => {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(() => {
            updateLoading(false);
        });
    };

    const handleAddAccessToSite = () => {
        setAddAccessToSiteDialogOpen(false);
        updateLoading(true);
        UserService.createPermission(addAccessToUser.id, {
            type: 'survey',
            permission: {
                survey: [addAccessToSiteAndSurvey.surveyCombinedHash],
                site: [addAccessToSiteAndSurvey.siteWithSurvey.site.id],
                report: [],
                company: [company.id]
            },

        }, {user: {type: 'user', id: addAccessToUser.id}}).then(() => {
            setRefresh(refresh+1);
            setAddAccessToSiteAndSurvey(null);
            setAddAccessToUser(null);
        }).catch(error => {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(() => {
            updateLoading(false);
        });
    };

    const handleToggleComments = (userId, userPermission) => {
        updateLoading(true);
        const permission = {type: 'survey', permission: {
            survey: userPermission.data.permission.survey,
            site: userPermission.data.permission.site,
            report: -1 === userPermission.data.permission.report.indexOf('qualitative-comments') ? userPermission.data.permission.report.concat('qualitative-comments') : userPermission.data.permission.report.filter(r => r !== 'qualitative-comments'),
            company: userPermission.data.permission.company,
        }};
        UserService.patchPermission(userId, userPermission.id, permission).then(() => {
            setRefresh(refresh+1);
        }).catch(error => {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(() => {
            updateLoading(false);
        });
    }

    const handleOpenCreateUserDialog = () => {
        setCreateUser({
            name: '',
            email: '',
            password: randomPassword(),
        });
        setCreateUserDialogOpen(true);
    }

    const handleCreateUser = (event) => {
        event.preventDefault();
        updateLoading(true);
        setCreateUserDialogOpen(false);

        const newUserValues = {
            enabled: true,
            role: 'premium',
            name: createUser.name.trim(),
            email: createUser.email.trim(),
            emailVerified: true,
            password: createUser.password,
        };

        UserService.createUser(newUserValues, {company: company}).then(() => {
            updateNotification(true, intl.formatMessage({id: 'account.accountCreated', defaultMessage: 'Account created'}), 'success')
            setRefresh(refresh+1);
        }).catch((error) => {
            if (error && error.response && error.response.status === 409) {
                updateNotification(true, 'Could not create user', 'error');
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }

        }).then(() => {
            updateLoading(false);
        });
    }

    const handleChangeCreateUser = name => event => {
        setCreateUser({ ...createUser, [name]: event.target.value });
    };

    const tableRows = () => {
        if (!sitesWithSurveys) {
            return null;
        }

        const rows = [];
        Object.keys(sitesWithSurveys).forEach((curSiteId, i) => {
            let showSite = false;
            if (onlyShowSurveys.length === 0 && onlyShowUsers.length === 0 && Object.keys(sitesWithSurveys[curSiteId].surveys).length !== 0) {
                // Not filtering
                showSite = true;
            } else if (onlyShowSurveys.length !== 0 || onlyShowUsers.length !== 0) { // If one or both filters are active
                const showBasedOnSurveyFilter = onlyShowSurveys.filter(s => Object.keys(sitesWithSurveys[curSiteId].surveys).includes(s.attributes.combinedHash));
                const showBasedOnUserFilter = Object.keys(sitesWithSurveys[curSiteId].surveyUsers).filter(ch => {
                    return sitesWithSurveys[curSiteId].surveyUsers[ch].map(su => su.user.id).filter(suId => onlyShowUsers.map(u => u.id).includes(suId)).length > 0;
                });

                if (onlyShowSurveys.length !== 0 && onlyShowUsers.length === 0) {
                    // Filtering on surveys only
                    showSite = showBasedOnSurveyFilter.length > 0;
                } else if (onlyShowSurveys.length === 0 && onlyShowUsers.length !== 0) {
                    // Filtering on users only
                    showSite = showBasedOnUserFilter.length > 0;
                } else {
                    // Filtering on both surveys and users, show only if they overlap
                    showSite = showBasedOnSurveyFilter.map(s => s.attributes.combinedHash).filter(x => showBasedOnUserFilter.includes(x)).length > 0;
                }
            }

            if (showSite && groupByDemographic && Object.keys(surveyDemographics).length) {
                // Filtering based survey demographic option
                const [grpHash, grpDem, grpOpt] = groupByDemographic.split('|');
                if (!Object.keys(sitesWithSurveys[curSiteId].surveys).includes(grpHash)) {
                    showSite = false;
                } else if (surveyDemographics[grpHash] &&
                    surveyDemographics[grpHash][grpDem] &&
                    surveyDemographics[grpHash][grpDem][grpOpt] &&
                    -1 === surveyDemographics[grpHash][grpDem][grpOpt].indexOf(sitesWithSurveys[curSiteId].site.attributes.value)
                ) {
                    showSite = false;
                }
            }

            if (!showSite) {
                return;
            }

            const siteRows = [
                <TableRow key={curSiteId} style={{backgroundColor: '#0000000a'}}>
                    <TableCell style={{borderBottom: 0}}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <Typography variant="body1" style={{paddingRight: 6}}>
                                <strong className={classes.tooltip} data-title={sitesWithSurveys[curSiteId].site.attributes.name}>{sitesWithSurveys[curSiteId].site.attributes.value}</strong>
                            </Typography>
                            {sitesWithSurveys[curSiteId].userDiff ? <span className={classes.tooltip} data-title="Site has differences in user access across surveys"><InfoIcon fontSize="small" style={{color: '#2196f3'}} /></span> : null}
                            {sitesWithSurveys[curSiteId].siteMapping ? <span className={classes.tooltip} data-title={'Site is mapped with: '+sitesWithSurveys[curSiteId].siteMapping.join(', ')}><ListAltIcon fontSize="small" style={{color: '#5cba98'}} /></span> : null}
                        </div>
                    </TableCell>
                    <TableCell align="right" style={{borderBottom: 0}}>
                        <RouterLink to={'/sites/'+curSiteId} className={classes.tooltip} data-title={intl.formatMessage({id: 'generic.edit', defaultMessage: 'Edit'}) + ' ' + intl.formatMessage({id: 'generic.site', defaultMessage: 'Site'})}>
                            <EditIcon fontSize="small" style={{ color: '#f57c00'}} />
                        </RouterLink>
                    </TableCell>
                </TableRow>
            ];

            Object.keys(sitesWithSurveys[curSiteId].surveys).forEach((curSiteSurveyCombinedHash, k) => {
                const siteUserCount = sitesWithSurveys[curSiteId].surveyUsers[curSiteSurveyCombinedHash] ? sitesWithSurveys[curSiteId].surveyUsers[curSiteSurveyCombinedHash].length : 0;

                let showSurvey = false;
                if (onlyShowSurveys.length === 0 && onlyShowUsers.length === 0) {
                    showSurvey = true;
                } else {
                    const showBasedOnSurveyFilter = onlyShowSurveys.some(s => s.attributes.combinedHash === curSiteSurveyCombinedHash);
                    const showBasedOnUserFilter = (curSiteSurveyCombinedHash in sitesWithSurveys[curSiteId].surveyUsers) && sitesWithSurveys[curSiteId].surveyUsers[curSiteSurveyCombinedHash].map(su => su.user.id).filter(suId => onlyShowUsers.map(u => u.id).includes(suId)).length > 0;

                    if (onlyShowSurveys.length !== 0 && onlyShowUsers.length !== 0) {
                        showSurvey = showBasedOnSurveyFilter && showBasedOnUserFilter;
                    } else if (onlyShowSurveys.length === 0) {
                        showSurvey = showBasedOnUserFilter;
                    } else {
                        showSurvey = showBasedOnSurveyFilter;
                    }
                }

                if (!showSurvey) {
                    return;
                }

                siteRows.push(
                    <TableRow key={curSiteId+curSiteSurveyCombinedHash}>
                        {/* Survey title + collapse icon + user count */}
                        <TableCell colSpan={2} style={{paddingLeft: 32}}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <Link href="#" style={{paddingTop: 6}} onClick={(e) => {
                                    e.preventDefault()
                                    setExpanded(prevState => ({
                                        ...prevState,
                                        [curSiteId+curSiteSurveyCombinedHash]: !prevState[curSiteId+curSiteSurveyCombinedHash]
                                    }))
                                }}>
                                    {expanded[curSiteId+curSiteSurveyCombinedHash] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Link>
                                <span>{sitesWithSurveys[curSiteId].surveys[curSiteSurveyCombinedHash].attributes.title}</span>
                                <strong style={{paddingLeft: 16}}>
                                    {siteUserCount} {siteUserCount !== 1 ? intl.formatMessage({id: 'generic.users', defaultMessage: 'Users'}).toLowerCase() : intl.formatMessage({id: 'generic.user', defaultMessage: 'User'}).toLowerCase()}
                                </strong>
                            </div>
                        </TableCell>
                    </TableRow>
                );

                // Users table (inside a cell that spans 2 cols)
                siteRows.push(
                    <TableRow key={curSiteId+curSiteSurveyCombinedHash+'u'}>
                        <TableCell colSpan={2} style={{paddingTop: 0, paddingBottom: 0, borderBottom: 0}}>
                            <Collapse in={expanded[curSiteId+curSiteSurveyCombinedHash]} unmountOnExit>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{paddingLeft: 48}}>{intl.formatMessage({id: 'generic.name', defaultMessage: 'Name'})}</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell align="center">{intl.formatMessage({id: 'generic.created', defaultMessage: 'Created'})}</TableCell>
                                            <TableCell align="center">
                                                    <span className={classes.tooltip} data-title="No. of sites the user has access to in this survey"><strong>#</strong></span>
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {siteUserCount ? sitesWithSurveys[curSiteId].surveyUsers[curSiteSurveyCombinedHash].map((su, j) => {
                                            if (onlyShowUsers.length !== 0 && !onlyShowUsers.some(showUser => showUser.id === su.user.id)) {
                                                return null;
                                            }

                                            // Higlight user rows based on how many sites they have access to
                                            let userRowHiglight = null;
                                            if (userSiteCount[su.user.id] && userSiteCount[su.user.id][curSiteSurveyCombinedHash] && userSiteCount[su.user.id][curSiteSurveyCombinedHash].length === 1) {
                                                userRowHiglight = classes.single;
                                            } else if (userSiteCount[su.user.id] && userSiteCount[su.user.id][curSiteSurveyCombinedHash] && userSiteCount[su.user.id][curSiteSurveyCombinedHash].length > 1) {
                                                if (sitesWithSurveys[curSiteId].surveys[curSiteSurveyCombinedHash].relationships.sites &&
                                                    userSiteCount[su.user.id][curSiteSurveyCombinedHash].length === sitesWithSurveys[curSiteId].surveys[curSiteSurveyCombinedHash].relationships.sites.length
                                                ) {
                                                    userRowHiglight = classes.all;
                                                } else {
                                                    userRowHiglight = classes.multi;
                                                }
                                            }

                                            return (<TableRow key={j} className={userRowHiglight}>
                                                <TableCell style={{paddingLeft: 48}}>{su.user.attributes.name}</TableCell>
                                                <TableCell>{su.user.attributes.email}</TableCell>
                                                <TableCell align="center">
                                                    <DateFormat date={su.user.attributes.createdAt} />
                                                </TableCell>
                                                <TableCell align="center">
                                                    {userSiteCount[su.user.id] && userSiteCount[su.user.id][curSiteSurveyCombinedHash] ? userSiteCount[su.user.id][curSiteSurveyCombinedHash].length : null}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap',
                                                        justifyContent: 'flex-end'
                                                    }}>
                                                        <Link href="#" style={{padding: 6, cursor: su.readOnly ? 'default' : 'cursor'}} className={classes.tooltip} data-title={su.readOnly ? 'User has all surveys/sites permission' : 'Remove access to site'} onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!su.readOnly) {
                                                                handleRemoveAccess(su.user.id, su.userPermission, curSiteId);
                                                            }
                                                        }}>
                                                            <RemoveCircleOutlineIcon fontSize="small" style={su.readOnly ? {color:'#ccc'} : {color: '#e41c4c'}} />
                                                        </Link>
                                                        <Link href="#" style={{padding: 6}} className={classes.tooltip} data-title="Toggle comments access" onClick={(e) => {
                                                            e.preventDefault();
                                                            handleToggleComments(su.user.id, su.userPermission)
                                                        }}>
                                                            {su.userPermission.data.permission.report && -1 !== su.userPermission.data.permission.report.indexOf('qualitative-comments') ?
                                                                <MessageIcon fontSize="small" style={{color:'green'}} />
                                                            :
                                                                <SpeakerNotesOffIcon fontSize="small" style={{color:'grey'}} />
                                                            }
                                                        </Link>
                                                        <Link href={'/users/'+su.user.id} style={{padding: 6}} className={classes.tooltip} data-title={intl.formatMessage({id: 'generic.edit', defaultMessage: 'Edit'}) + ' ' + intl.formatMessage({id: 'generic.user', defaultMessage: 'User'})}>
                                                            <EditIcon fontSize="small" style={{ color: '#f57c00'}} />
                                                        </Link>
                                                        <Link href="#" style={{padding: 6}} className={classes.tooltip} data-title={intl.formatMessage({id: 'generic.delete', defaultMessage: 'Delete'}) + ' ' + intl.formatMessage({id: 'generic.user', defaultMessage: 'User'})} onClick={(e) => {
                                                            e.preventDefault();
                                                            setDeleteUserId(su.user.id);
                                                            setDeleteUserDialogOpen(true);
                                                        }}>
                                                            <DeleteIcon fontSize="small" style={{color: '#e41c4c'}} />
                                                        </Link>
                                                    </div>
                                                </TableCell>
                                            </TableRow>);
                                        }) : null}
                                        <TableRow>
                                            <TableCell colSpan={3} style={{paddingLeft: 48}}>
                                                <span className={classes.tooltip} data-title="Add access for user to site">
                                                    <IconButton className={classes.rowButton} onClick={() => {
                                                        setAddAccessToSiteAndSurvey({siteWithSurvey: sitesWithSurveys[curSiteId], surveyCombinedHash: curSiteSurveyCombinedHash });
                                                        setAddAccessToSiteDialogOpen(true);
                                                        setAddAccessToUser(null);
                                                    }}>
                                                        <AddCircleOutlineIcon fontSize="small" style={{color: '#4caf50'}} />
                                                    </IconButton>
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                );
            });
            rows.push(...siteRows);
        });

        return rows;
    };

    return (
        <Fragment>
            <Grid container>
                <Grid item xs={3}>
                    <Typography variant="h6">
                        {company ? 'User Access for: '+company.attributes.name : ''}
                    </Typography>
                    <Tooltip title="Expand all">
                        <IconButton className={classes.rowButton} onClick={() => {
                            const allExpanded = {};
                            Object.keys(expanded).forEach(k => {allExpanded[k] = true;});
                            setExpanded(allExpanded);
                        }}>
                            <ExpandMoreIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Minimise all">
                        <IconButton className={classes.rowButton} onClick={() => {
                            const allExpanded = {};
                            Object.keys(expanded).forEach(k => {allExpanded[k] = false;});
                            setExpanded(allExpanded);
                        }}>
                            <ExpandLessIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'center'}}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Autocomplete
                                fullWidth
                                multiple
                                options={surveys ? surveys : []}
                                loading={surveys === null}
                                value={onlyShowSurveys}
                                getOptionSelected={(opt, val) => opt.attributes.combinedHash === val.attributes.combinedHash}
                                getOptionLabel={(opt) => (opt.relationships['survey-title'] ? opt.relationships['survey-title'].data.title : opt.attributes.title)}
                                onChange={(event, selected) => {
                                    setOnlyShowSurveys(selected);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{marginTop:0, paddingRight: 6}}
                                        margin="normal"
                                        helperText="Filter on one or more surveys"
                                        label={intl.formatMessage({id: 'generic.surveys', defaultMessage: 'Surveys'})}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                fullWidth
                                multiple
                                options={users ? users : []}
                                loading={users === null}
                                value={onlyShowUsers}
                                getOptionSelected={(opt, val) => opt.id === val.id}
                                getOptionLabel={(opt) => opt.attributes.name + ' - ' + opt.attributes.email}
                                onChange={(event, selected) => {
                                    setOnlyShowUsers(selected);
                                }}
                                renderOption={(opt) => (
                                    <div>
                                        {opt.attributes.name}
                                        <div>
                                            <Typography variant="caption" style={{fontWeight: 500}}>
                                                {opt.attributes.email}
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{marginTop:0, paddingLeft: 6, paddingRight: 6}}
                                        margin="normal"
                                        helperText="Filter on one or more users"
                                        label={intl.formatMessage({id: 'generic.users', defaultMessage: 'Users'})}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl className={classes.formControl} fullWidth style={{paddingLeft: 6, textAlign: 'left'}}>
                                <InputLabel>Show sites in demographic</InputLabel>
                                <Select value={groupByDemographic} MenuProps={{disablePortal: true, style: {zIndex: 1401}}} onChange={(event) => {
                                    if (event.target.value !== undefined) {
                                        setGroupByDemographic(event.target.value)
                                    }
                                }}>
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {surveyDemographicList.map((sd, i) => {
                                        if (sd.isGroup) {
                                            return <ListSubheader key={i} disableSticky={true} style={{paddingLeft: sd.padding}}>{sd.title}</ListSubheader>
                                        }
                                        return <MenuItem key={i} value={sd.value} style={{paddingLeft: sd.padding}}>{sd.title}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>Filter on sites with respondents in the selected demographic</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box py={2} textAlign="center">
                                <span style={{color: 'rgba(27, 182, 214, 0.4)', fontSize: '1.2rem'}}>◼</span> = single site
                                <span style={{color: 'rgba(146, 191, 32, 0.4)', fontSize: '1.2rem', paddingLeft: 10}}>◼</span> = multi site
                                <span style={{color: 'rgba(186, 85, 211, 0.4)', fontSize: '1.2rem', paddingLeft: 10}}>◼</span> = all sites in survey
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} style={{textAlign: 'right'}}>
                    <Typography variant="h6">
                        Accounts used: {users ? users.length : '-'} {company && company.attributes.accountLimit ? ' of '+company.attributes.accountLimit : ''}
                        {(company && company.attributes.accountLimit === null) || (company && users && users.length < company.attributes.accountLimit) ?
                            <span className={classes.tooltip} data-title="Create user">
                                <IconButton className={classes.rowButton} onClick={() => handleOpenCreateUserDialog()}>
                                    <AddCircleOutlineIcon fontSize="small" style={{color: '#4caf50'}} />
                                </IconButton>
                            </span>
                        : null}
                    </Typography>
                    {usersWithNoAccess.length ?
                        <Link href="#" style={{ textDecoration: 'underline' }} onClick={e => {
                            e.preventDefault();
                            setUsersWithNoAccessDialogOpen(true);
                        }}>
                            {usersWithNoAccess.length} {usersWithNoAccess.length > 1 ? intl.formatMessage({id: 'generic.users', defaultMessage: 'Users'}).toLowerCase() : intl.formatMessage({id: 'generic.user', defaultMessage: 'user'}).toLowerCase()} with no site access
                        </Link>
                    : null}
                </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{borderBottom: 0}}></TableCell>
                        <TableCell style={{borderBottom: 0}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows()}
                </TableBody>
            </Table>
            <Dialog
                open={usersWithNoAccessDialogOpen}
                onClose={() => {setUsersWithNoAccessDialogOpen(false)}}
            >
                <DialogTitle><FormattedMessage id="generic.users" defaultMessage="Users" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The following users have no site access
                    </DialogContentText>
                    <ul>
                        {usersWithNoAccess.map(u => (
                            <li key={u.id}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    {u.attributes.name} - {u.attributes.email}
                                    <Link href="#" style={{padding: 3}} className={classes.tooltip} data-title={intl.formatMessage({id: 'generic.delete', defaultMessage: 'Delete'}) + ' ' + intl.formatMessage({id: 'generic.user', defaultMessage: 'User'})} onClick={(e) => {
                                        e.preventDefault();
                                        setUsersWithNoAccessDialogOpen(false);
                                        setDeleteUserId(u.id);
                                        setDeleteUserDialogOpen(true);
                                    }}>
                                        <DeleteIcon fontSize="small" style={{color: '#e41c4c'}} />
                                    </Link>
                                </div>
                            </li>
                        ))}
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setUsersWithNoAccessDialogOpen(false)}} color="primary" autoFocus>
                        <FormattedMessage id="generic.close" defaultMessage="Close" />
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteUserDialogOpen}
                onClose={() => {setDeleteUserDialogOpen(false)}}
            >
                <DialogTitle><FormattedMessage id="generic.delete" defaultMessage="Delete" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="users.confirmDelete" defaultMessage="This will delete the user and all associated data, continue?" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setDeleteUserDialogOpen(false)}} color="primary" autoFocus>
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleDeleteUser} color="secondary">
                        <FormattedMessage id="generic.confirm" defaultMessage="Confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={addAccessToSiteDialogOpen}
                onClose={() => {setAddAccessToSiteDialogOpen(false)}}
            >
                <DialogTitle><FormattedMessage id="generic.delete" defaultMessage="Delete" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add access to the site {addAccessToSiteAndSurvey ? <strong>{addAccessToSiteAndSurvey.siteWithSurvey.site.attributes.value}</strong> : '-'} for:
                    </DialogContentText>
                    <Autocomplete
                        fullWidth
                        options={users}
                        loading={false}
                        value={addAccessToUser}
                        getOptionSelected={(opt, val) => opt.id === val.id}
                        getOptionLabel={(opt) => opt.attributes.name + ' - ' + opt.attributes.email}
                        getOptionDisabled={(opt) => {
                            if (addAccessToSiteAndSurvey.surveyCombinedHash in addAccessToSiteAndSurvey.siteWithSurvey.surveyUsers) {
                                return addAccessToSiteAndSurvey.siteWithSurvey.surveyUsers[addAccessToSiteAndSurvey.surveyCombinedHash].some(curU => curU.user.id === opt.id)
                            }
                            return false;
                        }}
                        onChange={(event, selected) => {
                            setAddAccessToUser(selected);
                        }}
                        renderOption={(opt) => (
                            <div>
                                {opt.attributes.name}
                                <div>
                                    <Typography variant="caption" style={{fontWeight: 500}}>
                                        {opt.attributes.email}
                                    </Typography>
                                </div>
                            </div>
                        )}
                        renderInput={(params) => (
                            <TextField {...params}
                                label={intl.formatMessage({id: 'generic.user', defaultMessage: 'User'})}
                                required
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setAddAccessToSiteDialogOpen(false)}} color="primary" autoFocus>
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleAddAccessToSite} color="secondary">
                        <FormattedMessage id="generic.confirm" defaultMessage="Confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={createUserDialogOpen}
                onClose={() => {setCreateUserDialogOpen(false)}}
                closeAfterTransition={false}
            >
                <form onSubmit={handleCreateUser}>
                    <DialogTitle><FormattedMessage id="createUser.header" defaultMessage="Create User" /></DialogTitle>
                    <DialogContent>
                        <Grid container spacing={0} justifyContent="center">
                            <TextField
                                fullWidth
                                required
                                label="Name"
                                value={createUser.name}
                                onChange={handleChangeCreateUser('name')}
                                margin="normal" />
                            <TextField
                                fullWidth
                                required
                                label="Email"
                                type="email"
                                value={createUser.email}
                                onChange={handleChangeCreateUser('email')}
                                margin="normal" />
                            <TextField
                                fullWidth
                                label="Password"
                                type="password"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Copy the generated password to clipboard" placement="left-start">
                                                <IconButton onClick={() => {navigator.clipboard.writeText(createUser.password);}}>
                                                    <FileCopyOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                                value={createUser.password}
                                onChange={handleChangeCreateUser('password')}
                                margin="normal" />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => {setCreateUserDialogOpen(false)}} color="primary" autoFocus>
                            <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={createUser.name.trim() === '' || createUser.email.trim() === ''}
                        >
                            <FormattedMessage id="generic.create" defaultMessage="Create" />
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

        </Fragment>
    );
}